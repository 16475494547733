import "./WorkCard.css";

import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const WorkCard = (props) => {
  const location = useLocation();

  return (
    <div className="project-card">
      <h2 className="project-title">{props.title}</h2>
      <div className="pro-details">
        <p>{props.text}</p>
      </div>
      <div className="pro-btns">
        {(props.view && props.view !== "/") ||
        (props.view && location.pathname !== "/") ? (
          <NavLink to={props.view} className="btn">
            View
          </NavLink>
        ) : (
          <button disabled className="btn">
            View
          </button>
        )}
      </div>
    </div>
  );
};

export default WorkCard;
