import "./HeroSec.css";

import React from "react";

const HeroSec = ({ heading, paragraph }) => {
  return (
    <div className="hero-img">
      <div class="heading">
        <h1>{heading}</h1>
        <p>{paragraph}</p>
      </div>
    </div>
  );
};

export default HeroSec;
