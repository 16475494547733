import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import HeroSec from "../Components/HeroSec/HeroSec";
import ContactForm from "../Components/ContactForm/ContactForm";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <HeroSec heading="Contact Me" paragraph="Feel free to reach out to me" />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default Contact;
