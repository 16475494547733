import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Hero from "../Components/Hero/Hero";
import Footer from "../Components/Footer/Footer";
import Work from "../Components/WorkCard/Work";

import PersonalProjects from "../Components/WorkData/PersonalProjects";
import AcademicProjects from "../Components/WorkData/AcademicProjects";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Work title="Personal Projects" workcardData={PersonalProjects} />
      <Work title="Academic Projects" workcardData={AcademicProjects} />
      <Footer />
    </div>
  );
};

export default Home;
