const PersonalProjectCardData = [
  {
    title: "Umbreon Discord Bot",
    text: "Umbreon Discord Bot is a versatile tool developed in Python, designed to enhance server management and user interaction on Discord. It offers a range of features from moderation to music playback. The bot connects to a Lavalink server to stream music, supporting YouTube playback, deonstrating my willingness to constantly learn new technologies and frameworks, adapting to the needs of the project.",
    view: "https://github.com/FernandoJVideira/UmbreonBot",
  },
  {
    title: "My Portfolio",
    text: "This portfolio website, built with ReactJS and React Router, showcases my software development skills, projects, and experiences. It features a user-friendly interface with easy navigation. Each project in the portfolio section includes a brief description and a link to the GitHub repository for more details. The website is designed to provide a seamless experience across various devices.",
    view: "/",
  },
  {
    title: "Casa dos Óculos Web Store",
    text: "Currently I'm also working on a web store for a local business, Casa dos Óculos. The web store is being developed using ReactJS. It also hosts a REST API, developed in GOLang and using a PostgreSQL database, implementing the use of the Stripe API for payment processing. This project is still in its early stages, but I'm excited to see it through.",
  },
];
export default PersonalProjectCardData;
