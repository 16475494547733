import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import HeroSec from "../Components/HeroSec/HeroSec";
import Work from "../Components/WorkCard/Work";

import PersonalProjects from "../Components/WorkData/PersonalProjects";
import AcademicProjects from "../Components/WorkData/AcademicProjects";

const Project = () => {
  return (
    <div>
      <Navbar />
      <HeroSec
        heading="Projects"
        paragraph="Some of my Personal and Academic work"
      />
      <Work title="Personal Projects" workcardData={PersonalProjects} />
      <Work title="Academic Projects" workcardData={AcademicProjects} />
      <Footer />
    </div>
  );
};

export default Project;
