import { Link } from "react-router-dom";
import "./AboutContent.css";

import React from "react";

import Logo from "../../assets/logo.svg";

const AboutContent = () => {
  return (
    <div className="about">
      <div className="left">
        <h1>Who am I?</h1>
        <p>
          I am a Software Engineering student from Portugal, I am passionate
          about learning and applying new technologies and frameworks to create
          innovative and engaging applications. As mentioned, I am currently
          pursuing my Bachelor on Software Engineering after completing having
          completed a CTeSP in programming, where I gained a solid foundation in
          C, .NET, PHP, Java, and MySQL. I have also enhanced my skills and
          knowledge by taking online courses in Unity 3D, Swift/SwiftUI,
          Cypress, Git, and React, and by working as a QA/QC at VOID Software, a
          leading software development company, for five months. There, I
          contributed to the testing and quality assurance of several projects,
          using Cypress and Cucumber to automate and streamline the testing
          process, and Git to manage the source code and collaborate with the
          development team. I am eager to continue developing my competencies
          and exploring new opportunities in the software industry.
        </p>
        <Link to="/contact">
          <button className="btn">Contact Me</button>
        </Link>
      </div>
      <div className="right">
        <img className="logoPic" src={Logo} alt="React" />
      </div>
    </div>
  );
};

export default AboutContent;
