import { FaGithub, FaLinkedin, FaMailBulk, FaTwitter } from "react-icons/fa";
import "./Footer.css";

import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="email">
            <h4>
              <a href="mailto:info@fernandovideira.com">
                <FaMailBulk
                  size={25}
                  style={{ color: "#fff", marginRight: "2rem" }}
                />
                info@fernandovideira.com
              </a>
            </h4>
          </div>
          <div className="socials github">
            <h4>
              <a
                href="https://github.com/FernandoJVideira"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub
                  size={25}
                  style={{ color: "#fff", marginRight: "2rem" }}
                  cursor={"pointer"}
                />
                @FernandoJVideira
              </a>
            </h4>
          </div>
          <div className="socials twitter">
            <h4>
              <a
                href="https://twitter.com/fernandoj_02_"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter
                  size={25}
                  style={{ color: "#fff", marginRight: "2rem" }}
                  cursor={"pointer"}
                />
                @fernandoj_02_
              </a>
            </h4>
          </div>
          <div className="socials linkedin">
            <h4>
              <a
                href="https://www.linkedin.com/in/fernando-videira-info/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin
                  size={25}
                  style={{ color: "#fff", marginRight: "2rem" }}
                  cursor={"pointer"}
                />
                Fernando Videira
              </a>
            </h4>
          </div>
        </div>
        <div className="right">
          <h4>A bit about me</h4>
          <p>
            I'm Fernando Videira, a Software Engineering student in Portugal
            looking for some new projects and challenges and oportunities to
            learn new things about the programming world!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
