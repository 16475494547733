const AcademicProjectCardData = [
  {
    title: "Stuff&Go Web Store",
    text: "Stuff&Go is a web application dedicated to a supermarket chain. It allows the user to create an account, add items to a cart, and checkout. It also allows the user to see the history of their purchases. The application was developed using PHP, with the Yii2 framework, and a MySQL database. It also hosts a REST API, which implements the MQTT Broker for connection to the Mobile App.",
    view: "https://github.com/FernandoJVideira/PL2-G7_ProjetoPlatSI",
  },
  {
    title: "Stuff&Go Mobile App",
    text: "Stuff&Go is a mobile application dedicated to a supermarket chain. It allows the user to login to their account, add items to a cart, and checkout. It also allows the user to see the history of their purchases as well as taking a ticket to store queues. The application was developed using Android Studio, with the Java language, and connects to the REST API of the Web Store.",
    view: "https://github.com/FernandoJVideira/PL2-G7_ProjetoAMSI",
  },
  {
    title: "RestGest",
    text: "This application was developed as part of my 2 year CTeSP course. It is a restaurant management application, which allows the user to manage the restaurant's menu, orders and payments. It also allows the user to manage the employees, and their salaries. The application was developed using C#, with the .NET framework, Entity Framework, and a SQL Server database.",
    view: "https://github.com/FernandoJVideira/PSI_DA_PL2_G",
  },
];
export default AcademicProjectCardData;
