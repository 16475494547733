import "./ContactForm.css";

import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const form = useRef();
  const notify = () => {
    toast.success("Message Sent Successfully!");
  };

  const errorToast = () => {
    toast.error("Error Sending Message!");
  };

  const emptyForm = () => {
    toast.warning("Please fill all the fields!");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    let isEmpty = Array.from(form.current.elements).some(
      (input) => input.type !== "submit" && input.value.trim() === ""
    );

    if (isEmpty) {
      emptyForm();
      return;
    }

    emailjs
      .sendForm(
        "service_0oh6s0e",
        "template_4s8pg7r",
        form.current,
        "fSghIDn2dawqKYyH7"
      )
      .then(
        (result) => {
          console.log(result.text);
          notify();
        },
        (error) => {
          console.log(error.text);
          errorToast();
        }
      );
    e.target.reset();
  };
  return (
    <div className="form">
      <form ref={form} onSubmit={sendEmail}>
        <label htmlFor="name">Your Name</label>
        <input type="text" name="user_name" placeholder="Your name" />
        <label htmlFor="email">Email</label>
        <input type="text" name="user_email" placeholder="Your email" />
        <label htmlFor="subject">Subject</label>
        <input type="text" name="subject" placeholder="Subject" />
        <label htmlFor="message">Message</label>
        <textarea
          rows="6"
          name="message"
          placeholder="Write something"
        ></textarea>
        <button className="btn" type="submit">
          Submit
        </button>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </form>
    </div>
  );
};

export default ContactForm;
