import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import HeroSec from "../Components/HeroSec/HeroSec";
import Footer from "../Components/Footer/Footer";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <HeroSec
        heading="404"
        paragraph="The page you are looking for does not exist :("
      />
      <Footer />
    </>
  );
};

export default NotFound;
