import { Link } from "react-router-dom";
import "./Navbar.css";
import React, { useState } from "react";

import Logo from "../../assets/logo.png";

import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);
  const handleClick = () => setShowLinks(!showLinks);

  const [colorChange, setColorChange] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div className={colorChange ? "header header-bg" : "header"}>
      <div className="header-title">
        <div className="header-title-child">
          <a href="/">
            <img src={Logo} alt="logo" className="logo" />
          </a>
        </div>
        <div className="header-title-child">
          <Link to="/">
            <h1>Portfolio</h1>
          </Link>
        </div>
      </div>
      <ul className={showLinks ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/projects">Projects</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <div className="burger" onClick={handleClick}>
        {showLinks ? (
          <FaTimes size={30} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={30} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
